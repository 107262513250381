import { fetchMessages } from "./ChatActions";

export function getUserToken() {
  const token = localStorage.getItem("token");
  return token;
}

export function setUserToken(token) {
  return localStorage.setItem("token", token);
}

export function setUserProfileData(profileData) {
  return localStorage.setItem("userProfileData", JSON.stringify(profileData));
}
export function getUserLocationState() {
  const data = JSON.parse(localStorage.getItem("userProfileData"));
  return data?.location?.state?.en;
}
export function getUserLocationDistrict() {
  const data = JSON.parse(localStorage.getItem("userProfileData"));
  return data?.location?.district?.en;
}

export async function getMessages(offset = null, fetchDirection = null) {
  const messages = await fetchMessages(getUserToken(), offset, fetchDirection);
  return messages;
}

export function getMessageBlock(messageObj) {
  return {
    bot: {
      text: messageObj["bot"] || null,
      suggested_questions: messageObj["suggested_questions"] || null,
      disclaimer: messageObj["disclaimer"] || null,
      sources: messageObj["sources"] || null,
      take_feedback: messageObj["take_feedback"] || null,
      feedback: messageObj["feedback"] || null,
      welcome: messageObj["welcome"] || null,
      error: messageObj["error"] || null,
    },
    user: {
      text: messageObj["user"]["query"] || null,
      welcome: messageObj["welcome"] || null,
      image: messageObj["image"] || null,
    },
  };
}

export function getBotMessageBlock(messageObj) {
  return {
    bot: {
      text: messageObj["bot"] || null,
      suggested_questions: messageObj["suggested_questions"] || null,
      disclaimer: messageObj["disclaimer"] || null,
      sources: messageObj["sources"] || null,
      take_feedback: messageObj["take_feedback"] || null,
      feedback: messageObj["feedback"] || null,
      welcome: messageObj["welcome"] || null,
      error: messageObj["error"] || null,
    },
  };
}

export function getUserMessageBlock(input, defaultFlag, image) {
  return {
    user: {
      text: input,
      welcome: defaultFlag || null,
      image: image,
    },
  };
}

export function getProcessedCustomPayload(
  project,
  inputText,
  audioUrls,
  selectedLanguageCode,
  customQueryFlag,
  image = null
) {
  let payload = {
    values: [
      {
        value: inputText,
        type: "text",
      },
    ],
    audios: audioUrls,
    lang: selectedLanguageCode,
    image: image,
    custom_query_flag: customQueryFlag,
  };
  if (project === "GHS") {
    if (inputText !== localStorage.getItem("selectedDocument")) {
      payload["documents_to_search"] = localStorage
        .getItem("selectedDocument")
        .split(",");
    } else {
      payload["documents_to_search"] = "";
    }
  }
  return payload;
}

export const stopPlayingAudio = (audioRef) => {
  if (audioRef.current) {
    audioRef.current.pause();
  }
};

export function saveSelectedDocument(selectedDocument) {
  return localStorage.setItem("selectedDocument", selectedDocument);
}

export function getConsentFlag() {
  const consentFlag = localStorage.getItem("consent");
  return consentFlag;
}
export function saveConsentFlag(consentFlag) {
  return localStorage.setItem("consent", consentFlag);
}

export function getOnboardingFlag() {
  const onboarding = localStorage.getItem("onboarding");
  return onboarding;
}

export function saveOnboardingFlag(onboardingFlag) {
  return localStorage.setItem("onboarding", onboardingFlag);
}

export function getMobileNumber() {
  const mobileNumber = localStorage.getItem("mobile_number");
  return mobileNumber;
}

export function saveMobileNumber(mobileNumber) {
  return localStorage.setItem("mobile_number", mobileNumber);
}

export function setUserType(userType) {
  return localStorage.setItem("user_type", userType);
}

export function getUserType() {
  const userType = localStorage.getItem("user_type");
  return userType;
}

export function setUserRole(userRole) {
  return localStorage.setItem("user_role", userRole);
}

export function getUserRole() {
  const userRole = localStorage.getItem("user_role");
  return userRole;
}

export function setDefaultLanguage(defaultLanguage) {
  return localStorage.setItem("defaultLanguage", defaultLanguage);
}

export function getDefaultLanguage() {
  const defaultLanguage = localStorage.getItem("defaultLanguage");
  return defaultLanguage;
}

export function trackEvent(category, action, label = "") {
  const userType = getUserType();
  const userRole = getUserRole();
  const userLocationState = getUserLocationState();
  const userLocationDistrict = getUserLocationDistrict();
  if (window._paq) {
    window._paq.push(['setCustomDimension', 1, userType]);
    window._paq.push(['setCustomDimension', 2, userRole]);
    window._paq.push(['setCustomDimension', 3, userLocationState]);
    window._paq.push(['setCustomDimension', 4, userLocationDistrict]);
    window._paq.push(["trackEvent", category, action, label]);
  }
}