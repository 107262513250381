import React, { useEffect, useState } from "react";
import { trackEvent } from "../../../Actions/Utils";

const LogoutDialog = ({ project, textSize, open, handleClose, handleConfirm, message, selectedLanguage}) => {
  const [constants, setConstants] = useState(null);
  useEffect(() => {
    const loadConstants = async () => {
      const module = await import(`../../../NewConstants/LoginConstant`);
      setConstants(module);
    }

    loadConstants();
  },[]);
  const handleCloseIcon = () => {
    trackEvent("Logout", "Click", "Clicked on cross icon in logout confirmation box");
    handleClose();
  };
  const handleCloseBtn = () => {
    trackEvent("Logout", "Click", "Clicked on No, return to chat in the logout confirmation box");
    handleClose();
  };
  if (!constants) {
    return <div>Loading...</div>;
  }

  const { logoutText } = constants;
  return (
    
        <>
          {open && (
            <div>
              <div className="fixed inset-0 bg-gray-800 opacity-50"></div>
              <div className="fixed inset-0 flex items-center justify-center p-2">
                <div
                  className="relative bg-[var(--neutral-white)] p-6 rounded-lg shadow-xl w-[75%]"
                  style={{ fontSize: `${textSize}rem` }}
                >
                  <div className="relative overflow-y-scroll h-[100%]">
                    <div className="flex flex-col items-center justify-center mb-4">
                      <div 
                        className="leading-9 font-[600] mb-4 text-[var(--red-1)] text-center"
                        style={{ fontSize: `${textSize + 0.125}rem` }}
                        >
                      {logoutText.confirmation[selectedLanguage]}
                      </div>
                      {/* <div className="text-[16px]  font-[400] leading-6 text-center mb-4"> */}
                      <div
                            className={`rounded-lg flex  items-center  justify-center py-4 w-[90%] h-fit `}
                            type='button'
                            onClick={handleConfirm}
                            style={{
                                

                                backgroundColor: 'var(--primary-dark)',
                                color:'var(--neutral-white)'

                                
                                
                              }}
              
                        >
                            
                            {logoutText.yes[selectedLanguage]}
                      </div>

                        
                      {/* </div> */}
                      <div 
                        className="font-[400] leading-6 text-center mb-4 "
                        style={{ fontSize: `${textSize}rem` }}
                        >
                      <button
                            className={`flex gap-3  py-4 items-center justify-center rounded-lg text-[var(--primary-dark)]`}
                            onClick={handleCloseBtn}
                            
              
                        >
                        <img src={"/Assets/Images/arrowLeft.svg"} alt="logout" />
                        {logoutText.no[selectedLanguage]}
                      </button>

                        
                      </div>
                    </div>
                  </div>
                  <div
                    className="absolute top-[-1.25rem] right-[-1.25rem] rounded-full bg-[var(--almost-black)] flex items-center justify-center p-1 cursor-pointer"
                    onClick={handleCloseIcon}
                  >
                    <img src={"./Assets/Images/crossIcon.svg"} alt="Close" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    
    
    

};

export default LogoutDialog;
