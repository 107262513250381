import React, { useRef, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { transcribe } from "../../../Actions/ChatActions";
import AudioRecorder from "../AudioRecorder/AudioRecorder";
import { getUserToken } from "../../../Actions/Utils";
import RecordedAudioText from "../RecordedAudioText/RecordedAudioText";
import MicropohneOnboarding from "../MicrophoneOnboarding/MicrophoneOnboarding";
import { useNavigate } from "react-router-dom";
import { ToastSeverityMap } from "../../../NewConstants/ToastConstants";
import { trackEvent } from "../../../Actions/Utils";
const onboarding_delay = process.env.REACT_APP_ONBOARDING_DELAY;
const ChatInputButton = ({
  selectedLanguageCode,
  input,
  setInput = () => {},
  setAudioUrls = () => {},
  audioUrls,
  send = () => {},
  adjustTextareaHeight = () => {},
  isLoading,
  audioRef,
  stopPlayingAudio = () => {},
  setSpeakerEnabled = () => {},
  setToastErrorMessage = () => {},
  setShowToastMessage = () => {},
  setToastMessageSeverity = () => {}
}) => {
  let navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordOnboarding, setIsRecordOnboarding] = useState(false);
  const [Mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 })
  );
  const [recordingTime, setRecordingTime] = useState(0);
  const recorderRef = useRef(null);
  const [audioText, setAudioText] = useState("");
  const [processAudio, setProcessAudio] = useState(false);

  const startRecording = async () => {
    try {
      // Check microphone permissions
      const status = await navigator.permissions.query({ name: 'microphone' });
      if (status.state) {
        await Mp3Recorder.start();
        setIsRecording(true);
        recorderRef.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1);
        }, 1000);
      }
      else {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        stream.getTracks().forEach(track => track.stop());
        await Mp3Recorder.start();
        setIsRecording(true);
        recorderRef.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1);
        }, 1000);
      }
    } catch (error) {
      setRecordingTime(0);
      setToastErrorMessage("Please allow microphone");
      setToastMessageSeverity(ToastSeverityMap.error);
      setShowToastMessage(true);
      setInput("");
      setIsRecording(false);
      setAudioText("");
      setProcessAudio(false);
      setRecordingTime(0);
    }
  };
  

  const startRecordingOnboarding = () => {
    try {
      if(!isLoading) {
        stopPlayingAudio(audioRef);
        setSpeakerEnabled(false);
        setIsRecordOnboarding(true);
        setTimeout(() => {
          setIsRecordOnboarding(false);
          startRecording();
        }, 3 * onboarding_delay);
      }
    } catch(error) {
      console.error("Error startig audio recording onboarding:", error);
    }
    trackEvent("Mic Recording", "Click", "clicked on the mic button");
  }

  const stopRecording = () => {
      Mp3Recorder?.stop()?.getMp3()?.then(async ([buffer, blob]) => {
          setProcessAudio(true);
          const res = await transcribe(getUserToken(), selectedLanguageCode, blob);
          if (res?.status === 200) {
            setInput(res?.data?.transcript?.text);
            setAudioUrls([...audioUrls, res?.data?.s3_path]);
            setIsRecording(false);
            setAudioText(res?.data?.transcript?.text);
            setProcessAudio(false);
            setRecordingTime(0);
          }
          else if (res?.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
          else if (res?.response?.status === 500) {
            setToastErrorMessage(res?.response?.data?.detail);
            setToastMessageSeverity(ToastSeverityMap.error);
            setShowToastMessage(true);
            setInput("");
            setIsRecording(false);
            setAudioText("");
            setProcessAudio(false);
            setRecordingTime(0);
          }
          else {
            setToastErrorMessage("Audio Transcription Failed");
            setToastMessageSeverity(ToastSeverityMap.error);
            setShowToastMessage(true);
            setInput("");
            setIsRecording(false);
            setAudioText("");
            setProcessAudio(false);
            setRecordingTime(0);
          }
        })
        .catch((error) => {
          setToastErrorMessage(error?.data?.transcript?.text);
          setToastMessageSeverity(ToastSeverityMap.error);
          setShowToastMessage(true);
          setInput("");
          setIsRecording(false);
          setAudioText("");
          setProcessAudio(false);
          setRecordingTime(0);
        });
    clearInterval(recorderRef.current);
    trackEvent("Mic Recording", "Click", "stop button for stopping the recording");
  };

  function sendText() {
    if (!isLoading) {
      send(-1, input, true, null);
      setInput("");
      setAudioText("");
      adjustTextareaHeight(true);
    }
  }

  function sendMicText(inputText) {
    send(-1, inputText, true, null);
    setInput("");
    setAudioText("");
    trackEvent("Mic Recording", "Click", "Send button for sending the transcripted text");
  }

  return (
    <div>
      {(input?.length === 0) && 
        <div
          className="flex items-center justify-center md:p-3 p-2  bg-[var(--border-color)] rounded-xl cursor-pointer hover:bg-[var(--hover-primary-dark)] shadow-md w-[2.75rem] h-[100%]"
          onClick={startRecordingOnboarding}
        >
          <img src={"/Assets/Images/mic.svg"} alt="Mic" className="" />
        </div>
      }
      {(input?.length > 0) && 
        <div
          className={`flex items-center justify-center md:p-3 p-2  bg-[var(--border-color)] rounded-xl cursor-pointer hover:bg-[var(--hover-primary-dark)] shadow-md w-[2.75rem] h-[100%] ${input?.length > 0 ? '': 'pointer-events-none'}`}
          onClick={sendText}
        >
          <img src={"/Assets/Images/send.svg"} alt="Send" className="" />
        </div>
       } 
      {
        isRecording && <div>
            <div className='flex items-center justify-center md:p-3 p-2  bg-[var(--red-alert-2)] rounded-xl cursor-pointer' onClick={stopRecording}>
                <img src="/Assets/Images/stopIcon.svg" alt='Stop' className='md:h-[100%] h-[70%]' />
            </div>
        </div>
      }
      {isRecordOnboarding && (
          <MicropohneOnboarding selectedLanguageCode={selectedLanguageCode} onboarding_delay={onboarding_delay} />
        )
      }
      {isRecording && (
        <AudioRecorder selectedLanguageCode={selectedLanguageCode} recordingTime={recordingTime} onStop={stopRecording} showLoader={processAudio} />
      )}
      {audioText && (
        <RecordedAudioText
          audioText={audioText}
          selectedLanguageCode={selectedLanguageCode}
          send={sendMicText}
          setAudioText={setAudioText}
          setInput={setInput}
          setAudioUrls={setAudioUrls}
        />
      )}
    </div>
  );
};

export default ChatInputButton;
