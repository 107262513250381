import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { generateSpeech } from "../../../../Actions/ChatActions";
import { getMobileNumber, stopPlayingAudio } from "../../../../Actions/Utils";
import NextBtn from "../../../../Shared/Components/NextBtn/NextBtn";
import "./RegisterComponent.css";
import { register } from "../../../../Actions/UserActions";
import { districtPlaceholderLabel, statePlaceholderLabel } from "../../../../NewConstants/RegisterConstants";
import ToastMessage from "../../../../Shared/Components/ToastMessage/ToastMessage";
import { ToastSeverityMap } from "../../../../NewConstants/ToastConstants";
import platform from "platform";


const RegisterComponent = ({ project, selectedLanguage, audioRef}) => {
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    age: "",
    gender: "",
    mobile: getMobileNumber(),
    state: "",
    district: "",
    device_data: null,
    browser_data: null
  });
  const [showSpeakerActive, setShowSpeakerActive] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [constants, setConstants] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const { name, role, age, gender, mobile, state, district } = formData;
    if (state && typeof state === "string") {
      setSelectedState(JSON.parse(state)?.en || "");
    }
    setIsFormValid(
      name.trim() &&
      role &&
      Number(age) > 0 &&
      Number(age) <= 100 &&
      gender &&
      mobile.length === 10 &&
      state &&
      district
    );
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      // Only allow letters and spaces
      const regex = /^[a-zA-Z\s]*$/;
      if (regex.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === "age") {
      // Restrict age to integers only
      const numericValue = value.replace(/\D/g, ""); // Remove any non-digit characters
      setFormData({ ...formData, [name]: numericValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setToastErrorMessage("Please fill all required fields correctly.");
      setShowToastMessage(true);
      return;
    }
    try {
      formData.device_data = {
        "device_name": platform.manufacturer || "Unknown",
        "device_os": platform.os?.family || "Unknown",
        "device_version": platform.os?.version || "Unknown",
      }
      formData.browser_data = {
        "browser_name": platform.name || "Unknown",
        "browser_version": platform.version || "Unknown",
      }
      const mobileNumber = `+91${formData.mobile}`;
      formData.state = typeof formData.state === "string" ? JSON.parse(formData.state) : formData.state;
      formData.district = typeof formData.district === "string" ? JSON.parse(formData.district) : formData.district;

      const res = await register(mobileNumber, formData);
      if (res?.status === 200) {
        navigate("/otp-verify");
      }
      if(res?.status === 500) {
        setToastErrorMessage(res.data.detail || "Something went wrong, Please try again")
        setShowToastMessage(true);
      }
    } catch (error) {
      console.error("Form submission error:", error);
      setToastErrorMessage("An unexpected error occurred. Please try again.");
      setShowToastMessage(true);
    }
  };

  useEffect(() => {
    if(!getMobileNumber()) {
      navigate("/");
    }
    stopPlayingAudio(audioRef);
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const handleAudio = async () => {
      const res = await generateSpeech(formAudio[selectedLanguage], selectedLanguage);
      if (res?.data?.url) {
        const newAudio = new Audio(res?.data?.url);
        if (audioRef?.current) {
          audioRef.current.pause();
        }
        audioRef.current = newAudio;
        newAudio.play().catch((err) => {
          console.error("Error playing audio:", err);
        });
      } else {
        setToastErrorMessage(res?.data?.detail)
        setShowToastMessage(true);
        setShowSpeakerActive(false);
      }
    };
    if (showSpeakerActive) handleAudio();
  }, [showSpeakerActive]);

  const backArrowClick = () => {
    navigate("/");
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };

    const loadConstants = async () => {
      const module = await import(`../../../../NewConstants/RegisterConstants`);
      setConstants(module);
    }

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const {formAudio, heading, secondaryHeading, form_headings, rolePlaceholderLabel, roleDropdownLabels, genderPlaceholderLabel, genderDropdownLabels, stateDropdownLabels, districtDropdownLabels} = constants;

  return (
    <>
    <div className="consent-page h-screen w-screen bg-[var(--light-neutral-2)]">
      <div className="w-full flex items-center justify-between pt-4 text-[color:var(--black)] text-xl font-semibold form-header">
        <div className="loginNextBtn  pl-3 ">
          <NextBtn
            icon={"/Assets/Images/arrowLeftBlack.svg"}
            nextClick={backArrowClick}
          />
        </div>
        {showSpeakerActive ? (
          <div
            className="pr-6  "
            onClick={() => {
              setShowSpeakerActive(!showSpeakerActive);
              stopPlayingAudio(audioRef);
            }}
          >
          <img
                    src={"/Assets/Images/blueSpeaker.svg"}
                    alt="active Speaker"
                  />

          </div>
        ) : (
          <div 
            className=" pr-6"
            onClick={() => {
              setShowSpeakerActive(!showSpeakerActive);
            }}
          >
            <img
              src={"/Assets/Images/crossSpeakerIcon.svg"}
              alt="Inactive Speaker"
            />
          </div>
        )}
      </div>
      <div className="pb-10  bg-[var(--light-neutral-2)] flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-md px-6 py-2 rounded-md"
        >
          <h2 className="text-xl font-semibold text-blue-700 mb-2 pb-2">
          {heading[selectedLanguage]}
          </h2>
          <p className="text-gray-600 mb-4 pb-5">{secondaryHeading[selectedLanguage]}</p>

          <div className="mb-4 pb-2">
            <label className="block text-gray-700">
            {form_headings['name'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              aria-label="Name"
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="mb-4 pb-2">
            <label className="block text-gray-700" htmlFor="role">
            {form_headings['role'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <select
              name="role"
              id="role"
              value={formData.role}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 bg-white"
              style={{ height: "2.5rem", backgroundColor: "white", }}
            >
              <option
                key={"select your role"}
                id={"select your role"}
                value={""}
                style={{ height: "2.5rem", backgroundColor: "white" }}
                disabled>
                    {rolePlaceholderLabel[selectedLanguage]}
                </option>
              {roleDropdownLabels.map((option) => {
                return <option
                key={option["en"]}
                id={option["en"]}
                value={option["value"]}
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                {option[selectedLanguage]}
              </option>
              })}
            </select>
          </div>

          <div className="mb-4 pb-2">
            <label className="block text-gray-700">
            {form_headings['age'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="age"
              max="100"
              value={formData.age}
              aria-label="Age"
              required
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="mb-4 pb-2">
            <label className="block text-gray-700" htmlFor="district">
            {form_headings['gender'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <select
              name="gender"
              id="gender"
              value={formData.gender}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 bg-white"
              style={{ height: "2.5rem", backgroundColor: "white", }}
            >
              <option
                key={"select your gender"}
                id={"select your gender"}
                value={""}
                style={{ height: "2.5rem", backgroundColor: "white" }}
                disabled>
                    {genderPlaceholderLabel[selectedLanguage]}
                </option>
              {genderDropdownLabels.map((option) => {
                return <option
                key={option["en"]}
                id={option["en"]}
                value={option["value"]}
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                {option[selectedLanguage]}
              </option>
              })}
            </select>
          </div>

          <div className="mb-4 pb-2">
            <label className="block text-gray-700">
            {form_headings['mobile'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <input
              type="tel"
              placeholder="1234567890"
              required
              maxLength={10}
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              disabled
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              
            />
          </div>

          <div className="mb-4 pb-2">
            <label className="block text-gray-700" htmlFor="state">
            {form_headings['state'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <select
              name="state"
              id="state"
              value={formData.state}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 bg-white"
              style={{ height: "2.5rem", backgroundColor: "white" }}
            >
              <option
                key={"select your state"}
                id={"select your state"}
                value={""}
                style={{ height: "2.5rem", backgroundColor: "white" }}
                disabled>
                    {statePlaceholderLabel[selectedLanguage]}
                </option>
              {stateDropdownLabels.map((option) => {
                return <option
                key={option["en"]}
                id={option["en"]}
                value={JSON.stringify(option)}
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                {option[selectedLanguage]}
              </option>
              })}
            </select>
          </div>

          <div className="mb-4 pb-2">
            <label className="block text-gray-700" htmlFor="district">
            {form_headings['district'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <select
              name="district"
              id="district"
              value={formData.district}
              required
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 bg-white"
              style={{ height: "2.5rem", backgroundColor: "white" }}
            > 
              <option
                key={"select your district"}
                id={"select your district"}
                value={""}
                style={{ height: "2.5rem", backgroundColor: "white" }}
                disabled>
                    {districtPlaceholderLabel[selectedLanguage]}
                </option>
              {selectedState && districtDropdownLabels[selectedState].map((option) => {
                return <option
                key={option["en"]}
                id={option["en"]}
                value={JSON.stringify(option)}
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                {option[selectedLanguage]}
              </option>
              })}
            </select>
          </div>

          <button
            type="submit"
            className={`w-full py-2 px-4 rounded-md text-white bg-[var(--primary-dark)] hover:bg-[var(--border-color)]"
            }`}
          >
            {isFormValid ? form_headings['fill'][selectedLanguage] : form_headings['proceed'][selectedLanguage]}
          </button>
        </form>
      </div>
    </div>
    {
      showToastMessage && <ToastMessage message={toastErrorMessage} severity={ToastSeverityMap.error} direction={"bottom"} close={setShowToastMessage}/>
    }
    </>
  );
};

export default RegisterComponent;
