import { trackEvent } from "../../../Actions/Utils";
const DefaultChips = ({
    option,
    selectedLanguage,
    send = () => {},
    textSize,
    disabled,
    index,
    selectedChipIndex, 
    isLoading,
    onChipClick 
  }) => {
    function saveResponse(input, image) {
      send(index, input, image);
    }
  
    return (
      <div
        className={`flex items-center justify-start rounded-xl border border-solid border-1 border-[var(--primary-dark)] gap-3  text-[var(--primary-dark)] bg-[var(--neutral-white)]  min-h-[3rem] px-2 ${isLoading ? 'pointer-events-none' : ''}`}
          onClick={() => {
              onChipClick(option.value[selectedLanguage]);
              saveResponse(option.value[selectedLanguage], option.img);
              trackEvent("Default Chips", "Click", "Default chips from menu");
            }}
        style={{ fontSize: `${textSize}rem` }}
      >
        {option.img && <img
            src={option.img}
            alt={option.value[selectedLanguage]}
            className="h-100 w-100 mt-2"
          />}
        <div className="text-center">
          {option.value[selectedLanguage]}
        </div>
      </div>
    );
  };
  
  export default DefaultChips;
  