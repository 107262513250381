import { useState } from "react";
import { trackEvent } from "../../../Actions/Utils";

const QuestionChips = ({
  selectedLanguage,
  options,
  saveResponse = () => {},
  textSize,
  disabled,
  selectedChipIndex
}) => {
  return (
    <div className="flex flex-col">
      {options.map((option, idx) => {
        return (
          <div
            key={idx}
            className={`flex min-h-16 items-center rounded-lg border border-solid border-1 border-[var(--primary-dark)] gap-3 p-2 mb-2 ${disabled ? 'bg-[var(--light-neutral-3)] opacity-60 border-[var(--light-neutral-3)]': 'bg-[var(--neutral-white)]'} ${selectedChipIndex == idx ? 'opacity-100 bg-[var(--primary-dark)] text-[var(--neutral-white)]': 'text-[var(--primary-dark)]'}`}
            onClick={() => {
              saveResponse(idx, option[selectedLanguage], false, null);
              trackEvent("Default Chips	", "Click", "click on default questions");
            }}
            style={{fontSize: `${textSize}rem`}}
          >
            {option[selectedLanguage]}
          </div>
        );
      })}
    </div>
  );
};

export default QuestionChips;
