import QuestionChips from "../QuestionChips/QuestionChips";
import OptionChips from "../OptionChips/OptionChips";
import DisclaimerChatBlock from "../DisclaimerChatBlock/DisclaimerChatBlock";
import StarChatBlock from "../StarChatBlock/StarChatBlock";
import SpeakerChatBlock from "../SpeakerChatBlock/SpeakerChatBlock";
import FeedbackChatBlock from "../FeedbackChatBlock/FeedbackChatBlock";
import SourcesChatBlock from "../SourcesChatBlock/SourcesChatBlock";
import { useEffect, useState } from "react";
import { getUserRole } from "../../../Actions/Utils";
import FeedbackButtonBlock from "../FeedbackButtonBlock/FeedbackButtonBlock";
import Markdown from 'react-markdown'
import "./BotChatBlock.css"

const BotChatBlock = ({
  project,
  idx,
  followingIdx,
  selectedLanguage,
  item,
  speakerEnabled,
  initSpeechSynth = () => { },
  stopAudio = () => { },
  saveResponse = () => { },
  openSourceDialog = () => { },
  sendFeedback = () => { },
  time,
  textSize,
  disabled,
  markFavourite = () => { }
}) => {
  const [defaultChipsConstants, setDefaultChipsConstants] = useState(null);
  const [feedbackConstants, setFeedbackConstants] = useState(null);
  const [chatConstants, setChatConstants] = useState(null);
  const [errorConstants, setErrorConstants] = useState(null);

  useEffect(() => {
    const loadConstants = async () => {
      const defaultchipmodule = await import(`../../../NewConstants/OptionConstants`);
      const feedbackmodule = await import(`../../../NewConstants/FeedbackConstants`);
      const chatmodule = await import(`../../../NewConstants/ChatConstants`);
      const errormodule = await import(`../../../NewConstants/ErrorConstants`);
      setDefaultChipsConstants(defaultchipmodule);
      setFeedbackConstants(feedbackmodule);
      setChatConstants(chatmodule);
      setErrorConstants(errormodule);
    }

    loadConstants();
  }, []);
  
  if (!defaultChipsConstants || !feedbackConstants || !chatConstants || !errorConstants) {
    return <div>Loading...</div>;
  }

  const { NHSRCashaDefaultChipsList, WCDawwDefaultChipsList, CTDashaDefaultChipsList, CTDchoDefaultChipsList } = defaultChipsConstants;
  const { FeedbackConstant, FeedbackValues } = feedbackConstants;
  const { answerTypes, answerMethods, botDisclaimerMessage, defaultChipBlockText, welcomeMessageBlockText, noAnswerDefaultBlockText, summarizedAnswerTitle, validatedSameMessage } = chatConstants;
  const { errorCodeMap } = errorConstants;

  const defaultChipMarkup = () => ({ __html: defaultChipBlockText?.html[selectedLanguage] });

  const noAnswerBlockMarkup = () => ({ __html: noAnswerDefaultBlockText?.html[selectedLanguage] })

  const welcomeMessageMarkup = () => ({ __html: welcomeMessageBlockText?.html[selectedLanguage] });
  return (
    <div className='float-left relative flex flex-col leading-[1.5rem]'>
      {/* main message block */}
      {item?.bot?.text && (
        <div className="flex items-center justify-start">
          <div
            className={`relative mb-2 ${speakerEnabled === idx ? 'bg-[var(--primary-light-2)] border border-[var(--primary-medium)]' : item?.bot?.error?.code ? `flex items-start bg-[var(--neutral-white)] text-[var(--disclaimer-1)]` : item?.bot?.starred ? ' bg-[var(--star-yellow-3)] border border-[var(--star-yellow-2)]' : 'bg-[var(--neutral-white)]'} w-fit max-w-[77.5%] text-[color:var(--almost-black)] rounded-t-lg rounded-br-lg`}
            style={{ fontSize: `${textSize}rem` }}
          >
            {(item?.bot?.error?.code) && <img className="mt-5 pl-2 float-left" src="Assets/Images/info.svg"/> }
            {!(item?.bot?.error?.code) && <div className="float-right m-3">
              <StarChatBlock idx={idx} id={item?._id} starred={item?.bot?.starred} mark={markFavourite} />
            </div>}
            <div className="mb-4 mt-4 mx-3">
              {/* {item?.bot?.answer_type === answerTypes.Summarized && <div className={`flex items-center justify-start mb-3 ${item?.bot?.starred ? 'text-[var(--star-yellow-1)]' : 'text-[var(--dark-neutral-2)]'}`}>
                {item?.bot?.starred && <img src={"./Assets/Images/yellowSummarizedIcon.svg"} alt="summary" />}
                {!item?.bot?.starred && <img src={"./Assets/Images/summarizedIcon.svg"} alt="summary" />}
                <span className="pl-2">{summarizedAnswerTitle[selectedLanguage]}</span>
              </div>} */}
              {item?.bot?.welcome ? <div className='flex flex-col' dangerouslySetInnerHTML={welcomeMessageMarkup()}></div> : <Markdown>{item?.bot?.text[selectedLanguage]}</Markdown>}
            </div>
            {item?.bot?.answer_type === answerTypes.Summarized && (
              <DisclaimerChatBlock
                selectedLanguage={selectedLanguage}
                text={botDisclaimerMessage}
              ></DisclaimerChatBlock>
            )}
          </div>
          <div className="ml-4 flex flex-col gap-3">
            <SpeakerChatBlock
              idx={idx}
              title={item?.bot?.text[selectedLanguage]}
              options={item?.bot?.options}
              isPlaying={speakerEnabled === idx}
              startAudio={initSpeechSynth}
              stopAudio={stopAudio}
            />
            {item?.bot?.sources?.length > 0 && (
              <SourcesChatBlock
                sourceData={item?.bot?.sources}
                openSourceDialog={openSourceDialog}
              />
            )}
          </div>
        </div>
      )}
      {/* welcome default block */}
      {(project !== 'GHS') && (item?.bot?.welcome) && (
        <div className="flex items-center justify-start">
          <div
            className={`relative mb-2 ${speakerEnabled === followingIdx ? 'bg-[var(--primary-light-2)] border border-[var(--primary-medium)]' : 'bg-[var(--neutral-white)]'} w-fit max-w-[77.5%] text-[color:var(--almost-black)] rounded-t-lg rounded-br-lg`}
            style={{ fontSize: `${textSize}rem` }}
          >
            <div className="mb-8 mt-4 mx-3" dangerouslySetInnerHTML={defaultChipMarkup()}>
            </div>
          </div>
          <div className="ml-4 flex flex-col gap-3">
            <SpeakerChatBlock
              idx={followingIdx}
              title={defaultChipBlockText?.audio[selectedLanguage]}
              options={[]}
              isPlaying={speakerEnabled === followingIdx}
              startAudio={initSpeechSynth}
              stopAudio={stopAudio}
            />
            {item?.bot?.sources?.length > 0 && (
              <SourcesChatBlock
                sourceData={item?.bot?.sources}
                openSourceDialog={openSourceDialog}
              />
            )}
          </div>
        </div>
      )}
      {/* no answer child message block */}
      {(project !== 'GHS') && (item?.bot?.error?.code === errorCodeMap["No Answer"]) && (
        <div className="flex items-center justify-start">
          <div
            className={`relative mb-2 ${speakerEnabled === followingIdx ? 'bg-[var(--primary-light-2)] border border-[var(--primary-medium)]' : 'bg-[var(--neutral-white)]'} w-fit max-w-[77.5%] text-[color:var(--almost-black)] rounded-t-lg rounded-br-lg`}
            style={{ fontSize: `${textSize}rem` }}
          >
            <div className="mb-8 mt-4 mx-3" dangerouslySetInnerHTML={noAnswerBlockMarkup()}>
            </div>
          </div>
          <div className="ml-4 flex flex-col gap-3">
            <SpeakerChatBlock
              idx={followingIdx}
              title={defaultChipBlockText?.audio[selectedLanguage]}
              options={[]}
              isPlaying={speakerEnabled === followingIdx}
              startAudio={initSpeechSynth}
              stopAudio={stopAudio}
            />
            {item?.bot?.sources?.length > 0 && (
              <SourcesChatBlock
                sourceData={item?.bot?.sources}
                openSourceDialog={openSourceDialog}
              />
            )}
          </div>
        </div>
      )}
      {/* chips block */}
      {item?.bot?.options ? (
        item?.bot?.welcome ? (
          <div className={`mr-2 grid grid-cols-2 grid-rows-2 gap-2 mb-2 ${disabled ? 'pointer-events-none' : ''}`}>
            {project === 'NHSRC' && getUserRole() === "ASHA" && NHSRCashaDefaultChipsList.map((option, index) => {
              return (
                <OptionChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={saveResponse}
                  disabled={disabled}
                  index={index}
                  selectedChipIndex={item?.bot?.selectedChipIndex}
                  textSize={textSize}
                />
              );
            })}
            {project === 'WCD' && getUserRole() === "AWW" && WCDawwDefaultChipsList.map((option, index) => {
              return (
                <OptionChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={saveResponse}
                  disabled={disabled}
                  index={index}
                  selectedChipIndex={item?.bot?.selectedChipIndex}
                  textSize={textSize}
                />
              );
            })}
            {project === 'CTD' && getUserRole() === "ASHA" && CTDashaDefaultChipsList.map((option, index) => {
              return (
                <OptionChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={saveResponse}
                  disabled={disabled}
                  index={index}
                  selectedChipIndex={item?.bot?.selectedChipIndex}
                  textSize={textSize}
                />
              );
            })}
            {project === 'CTD' && getUserRole() === "CHO" && CTDchoDefaultChipsList.map((option, index) => {
              return (
                <OptionChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={saveResponse}
                  disabled={disabled}
                  index={index}
                  selectedChipIndex={item?.bot?.selectedChipIndex}
                  textSize={textSize}
                />
              );
            })}
          </div>
        ) : (
          <div className={`z-20 ml-4 w-fit max-w-[77.5%] mt-[-1.5rem] ${disabled ? 'pointer-events-none' : ''}`}>
            <QuestionChips
              selectedLanguage={selectedLanguage}
              options={item?.bot?.options}
              saveResponse={saveResponse}
              disabled={disabled}
              selectedChipIndex={item?.bot?.selectedChipIndex}
            />
          </div>
        )
      ) : null}
      {/* bot timestamp */}
      <div className="text-xs text-[color:var(--dark-neutral-2)] px-3 mb-2 mt-5">
        {time}
      </div>
      {/* feedback block */}
      {item?.bot?.take_feedback &&  (
          <div 
            className={`relative p-3 ${item?.bot?.feedback_type !== '' ? 'mb-14' : 'mb-2'} bg-[var(--neutral-white)] w-fit max-w-[77.5%] text-[color:var(--almost-black)] rounded-t-lg rounded-br-lg ${disabled ? 'pointer-events-none' : ''}`}
            style={{ fontSize: `${textSize}rem` }}
          >
            {FeedbackConstant.title[selectedLanguage]}
            {
              item?.bot?.feedback_type === '' ? 
                <FeedbackChatBlock sendFeedback={sendFeedback} selectedLanguage={selectedLanguage}></FeedbackChatBlock>
                : 
                <div className="absolute right-[-2rem] bottom-[-3rem]">
                    <FeedbackButtonBlock  feedback={item?.bot?.feedback_type} selectedLanguage={selectedLanguage} />
                </div>
              }
          </div>
      )}
      {/* new feedback response flow */}
      {
        (item?.bot?.answer_type === answerTypes.Summarized) && (item?.bot?.answer_method === answerMethods.FAQ) && (item?.bot?.feedback_type === FeedbackValues.DISLIKE) ? <div>
        <div
          className={`${item["bot"]["welcome"] ? "pb-3" : ""
            } flex items-center justify-start`}
        >
          <div 
            className={`${speakerEnabled === followingIdx ? 'bg-[var(--primary-light-2)] border border-[var(--primary-medium)]' : 'bg-[var(--neutral-white)]'} w-fit max-w-[77.5%] text-[color:var(--almost-black)] rounded-t-lg rounded-br-lg`}
            style={{ fontSize: `${textSize}rem` }}
          >
            <div className={`mb-${item["bot"]["welcome"] ? "4" : "8"} mt-4 mx-3`}>
              {item?.bot?.suggested_questions?.title[selectedLanguage]}
            </div>
          </div>
          <div className="ml-4">
            <SpeakerChatBlock
              idx={followingIdx}
              title={item?.bot?.suggested_questions?.title[selectedLanguage]}
              options={item?.bot?.suggested_questions?.options}
              isPlaying={speakerEnabled === followingIdx}
              startAudio={initSpeechSynth}
              stopAudio={stopAudio}
            />
          </div>
        </div>
        {item?.bot?.suggested_questions?.options ? (
          <div className={`ml-4 w-fit max-w-[77.5%] mt-[-0.5rem] ${disabled ? 'pointer-events-none' : ''}`}>
            <QuestionChips
              selectedLanguage={selectedLanguage}
              options={item?.bot?.suggested_questions?.options}
              saveResponse={saveResponse}
              disabled={disabled}
              selectedChipIndex={item?.bot?.selectedChipIndex}
            />
          </div>
        ) : (
          <div className={`mr-6 grid grid-cols-2 grid-rows-2 gap-3 mb-4 ${disabled ? 'pointer-events-none' : ''}`}>
            {" "}
            {project === 'NHSRC' && getUserRole() === "ASHA" && NHSRCashaDefaultChipsList.map((option, index) => {
              return (
                <OptionChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={saveResponse}
                  disabled={disabled}
                  index={index}
                  selectedChipIndex={item?.bot?.selectedChipIndex}
                  textSize={textSize}
                />
              );
            })}
            {project === 'WCD' && getUserRole() === "AWW" && WCDawwDefaultChipsList.map((option, index) => {
              return (
                <OptionChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={saveResponse}
                  disabled={disabled}
                  index={index}
                  selectedChipIndex={item?.bot?.selectedChipIndex}
                  textSize={textSize}
                />
              );
            })}
            {project === 'CTD' && getUserRole() === "ASHA" && CTDashaDefaultChipsList.map((option, index) => {
              return (
                <OptionChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={saveResponse}
                  disabled={disabled}
                  index={index}
                  selectedChipIndex={item?.bot?.selectedChipIndex}
                  textSize={textSize}
                />
              );
            })}
            {project === 'CTD' && getUserRole() === "CHO" && CTDchoDefaultChipsList.map((option, index) => {
              return (
                <OptionChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={saveResponse}
                  disabled={disabled}
                  index={index}
                  selectedChipIndex={item?.bot?.selectedChipIndex}
                  textSize={textSize}
                />
              );
            })}
          </div>
        )}
      </div> : (item?.bot?.answer_type === answerTypes.Summarized || item?.bot?.answer_type === answerTypes.Validated || item?.bot?.answer_type === answerTypes.NotValidated ) && (item?.bot?.answer_method === answerMethods.FAQ || item?.bot?.answer_method === answerMethods.PAGE_SEARCH) && (item?.bot?.feedback_type === FeedbackValues.LIKE || item?.bot?.feedback_type === FeedbackValues.DISLIKE) ? <div className="p-3 mb-2 bg-[var(--neutral-white)] w-fit max-w-[77.5%] text-[color:var(--almost-black)]
       rounded-t-lg rounded-br-lg" style={{ fontSize: `${textSize}rem` }}>
              {validatedSameMessage[selectedLanguage]}
            </div> : <></>
      }
    </div>
  );
};

export default BotChatBlock;
