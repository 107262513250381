import { useEffect, useState } from "react";
import "./NavBar.css";
import { trackEvent } from "../../../Actions/Utils";

const NavBar = ({ project, selectedLanguage, textSize, toggleMenu = () => {}, toggleHelp = () => {} }) => {
  const [constants, setConstants] = useState(null);
  const hideHeaderIconFlag = process.env.REACT_APP_HIDE_HEADER_ICON === 'true';
  useEffect(() => {
    const loadConstants = async () => {
      const module = await import(`../../../NewConstants/NavConstants`);
      setConstants(module);
    }

    loadConstants();
  }, []); 

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { navTitle } = constants;

  const handleFeedbackClick = () => {
    toggleHelp();
    trackEvent("Feedback/Support", "Click", "Open Feedback/Support Icon (NavBar)");
  };
  const handletoggleMene = () => {
    toggleMenu();
    trackEvent("Hamburger", "Click", "Open Side Menu");
  };
  return (
    <div className="flex justify-between items-center pt-2 px-4 z-0 navBarDiv">
      <div onClick={handletoggleMene}>
        <img src={"/Assets/Images/NavBar.svg"} alt="Navbar" />
      </div>
      <div className={`font-semibold flex items-center justify-center gap-2.5 py-4 nameBarDiv`} style={{fontSize: `${textSize}rem`}}>
        {/* {!hideHeaderIconFlag &&<img className="h-10 w-10 rounded-full" src={"/Assets/Images/asha_icon_square.svg"} alt='Asha'/>} */}
        {navTitle[selectedLanguage]}
      </div>
      <div onClick={handleFeedbackClick}>
        <img src={"/Assets/Images/helpCircle.svg"} alt="Help" />
      </div>
    </div>
  );
};

export default NavBar;
