export const micRecording = {
  title: {
    "en": "Recording in-progress",
    "hi": "रिकॉर्डिंग जारी है"
  },
  subtitle: {
    "en": "Click stop when done",
    "hi": "रिकॉर्डिंग बंद करने के लिए “बंद करें” दबाएँ।"
  },
  stop: {
    "en": "Stop",
    "hi": "बंद करें"
  }
};

export const recordedAudio = {
  title: {
    "en": "Verify your question below",
    "hi": "अपना सवाल जांच लें।"
  },
  recordAgain: {
    "en": "Delete",
    "hi": "डिलीट"
  },
  send: {
    "en": "Send",
    "hi": "भेजें"
  }
};

export const deleteAudio = {
  title: {
    "en": "Delete this recording ?",
    "hi": "रिकॉर्डिंग डिलीट करें ?"
  },
  yes: {
    "en": "Delete and go to chat",
    "hi": "डिलीट करें और चैट पर वापस जाएं"
  },
  no: {
    "en": "No, go back to editing",
    "hi": "नहीं, एडिट पर लौटें"
  }
};
