import React, { useState, useRef } from "react";
import {
  deleteAudio,
  recordedAudio,
} from "../../../NewConstants/RecorderConstant";
import { trackEvent } from "../../../Actions/Utils";

const RecordedAudioText = ({
  audioText,
  selectedLanguageCode,
  send = () => { },
  setAudioText = () => { },
  setInput = () => { },
  setAudioUrls = () => { }
}) => {
  const [text, setText] = useState(audioText);
  const [recordAgain, setRecordAgain] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const textareaRef = useRef(null);

  const handleRecordAgain = () => {
    setAudioText("");
    setInput("");
    setRecordAgain(false);
    setAudioUrls([]);
    trackEvent("Mic Recording", "Click", "Yes I want to record again button");
  };
  const handleIconClick = () => {
    setEditMode(true);
    // Focus on the textarea when icon is clicked
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };
  const handleBlur = () => {
    setEditMode(false);
  };

  return (
    <div>
      <div className="z-30 fixed inset-0 bg-gray-800 opacity-50"></div>
      <div className="z-30 fixed inset-0">
        {recordAgain ? (
          <div className="flex items-center justify-center h-full">
            <div className="relative bg-[var(--neutral-white)] px-6 py-10 rounded-lg shadow-xl w-[80%]">
              <div className="flex items-center justify-center mb-4">
                <img className="h-100" src={"./Assets/Images/bigTrash.svg"} alt="trash" />
              </div>
              <div className="text-2xl font-semibold text-center text-[color:var(--dark-neutral-3)]">
                {deleteAudio.title[selectedLanguageCode]}
              </div>
              <div className="flex flex-col items-center gap-2 mt-10">
                <div
                  className="font-[400] rounded-lg py-3 px-4 w-full text-center bg-[var(--red-1)] text-[color:var(--neutral-white)]"
                  onClick={handleRecordAgain}
                >
                  {deleteAudio.yes[selectedLanguageCode]}
                </div>
                <div
                  className="font-[500] rounded-lg py-3 px-4 w-full text-center bg-[var(--primary-light)] text-[color:var(--primary-dark)]"
                  onClick={() => {
                    setRecordAgain(false);
                    trackEvent("Mic Recording", "Click", "No, go back to editing button");
                  }}
                >
                  {deleteAudio.no[selectedLanguageCode]}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-end justify-center h-full">
            <div className="max-h-1/3 h-fit w-full bg-[--primary-medium-2] rounded-t-xl px-7 pt-8 pb-6 flex flex-col justify-center gap-3 recorededTextBox">
              <div className="text-xl text-[color:var(--dark-neutral-text)] font-semibold font-['Noto_Sans']">
                {recordedAudio.title[selectedLanguageCode]}
              </div>

              <div style={{ position: "relative" }}>
                <textarea
                  ref={textareaRef}
                  rows={4}
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  autoFocus
                  onBlur={handleBlur}
                  className="rounded-xl border border-[var(--light-neutral-7)] text-[color:var(--dark-neutral-4)] text-xl w-full py-5 px-4 relative"
                  style={{ paddingRight: "40px" }} // Adjust padding for icon
                />
                {!editMode && (
                  <div
                    style={{
                      position: "absolute",
                      top: "80%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={handleIconClick}
                  >
                    <img src={"/Assets/Images/edit_recording.png"} alt="Edit icon" />
                  </div>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div
                  className="flex items-center justify-center border border-[var(--red-1)] py-3 px-4 gap-1 text-[color:var(--red-1)] rounded-md cursor-pointer"
                  onClick={() => {
                    setRecordAgain(true);
                    trackEvent("Mic Recording", "Click", "clicked on delete button");
                  }}
                >
                  <img src={"/Assets/Images/trash.svg"} alt="Mic" />
                  {recordedAudio.recordAgain[selectedLanguageCode]}
                </div>
                <div
                  className={`flex items-center justify-center border border-[var(--primary-dark)] bg-[var(--primary-dark)] text-[color:var(--neutral-white)] py-3 px-4 gap-2 rounded-md ${text.length > 0 ? "" : "pointer-events-none"
                    }`}
                  onClick={() => send(text)}
                >
                  <img src={"/Assets/Images/whiteSend.svg"} alt="Send" />
                  {recordedAudio.send[selectedLanguageCode]}
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecordedAudioText;
