import React, { useEffect, useState, useRef } from "react";
import TopBar from "../TopBar/TopBar";
import BottomBar from "../BottomBar/BottomBar";
import ChatQueue from "../ChatQueue/ChatQueue";
import SourceDialog from "../../../Shared/Components/SourceDialog/SourceDialog";
import {
  getMessages,
  getProcessedCustomPayload,
  getUserMessageBlock,
  getUserRole,
  getUserToken,
  saveSelectedDocument,
  stopPlayingAudio,
} from "../../../Actions/Utils";
import { useNavigate } from "react-router-dom";
import { modelInresponsive, sendChatFeedback, starBotAnswer, submit } from "../../../Actions/ChatActions";
import Loader from "../../../Shared/Components/Loader/Loader";
import ToastMessage from "../../../Shared/Components/ToastMessage/ToastMessage";
import { ToastSeverityMap } from "../../../NewConstants/ToastConstants";
import HelpFeedbackDialog from "../../../Shared/Components/HelpFeedbackDialog/HelpFeedbackDialog";
import { sendUserFeedback } from "../../../Actions/UserActions";
import { modelNotRespondingConstant } from "../../../NewConstants/ErrorConstants";
import { trackEvent } from "../../../Actions/Utils";

const ChatBody = ({
  project,
  selectedLanguage,
  textSize,
  setSelectedLanguage = () => {},
  decreaseTextSize = () => {},
  increaseTextSize = () => {},
}) => {
  let navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [chatArr, setChatArr] = useState([]);
  const userProfileData = JSON.parse(localStorage.getItem("userProfileData"));
  const [isLoading, setIsLoading] = useState(false);
  const [sourceDialogFlag, setSourceDialogFlag] = useState(false);
  const [selectedSourceData, setSelectedSourceData] = useState(null);
  const [currentBlock, setCurrentBlock] = useState(null);
  const chatBodyRef = useRef(null);
  const [oldestOffset, setOldestOffset] = useState(null);
  const [messageLoader, setMessageLoader] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const audioRef = useRef(null);
  const [speakerEnabled, setSpeakerEnabled] = useState(false);
  const [constants, setConstants] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(localStorage.getItem('selectedDocument') || null);
  const [currentOffset, setCurentOffset] = useState(null);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState("");
  const [toastMessageSeverity, setToastMessageSeverity] = useState("");
  const [scrollToBottonFlag, setScrollToBottomFlag] = useState(false);
  const [audioUrls, setAudioUrls] = useState([]);

  useEffect(() => {
    if (getUserToken()) {
      populateChat();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }

    const loadConstants = async () => {
      const module = await import(`../../../NewConstants/ChatConstants`);
      setConstants(module);
    };

    loadConstants();

    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setSpeakerEnabled(false);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = () => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener("popstate", handlePopState);
    setScrollToBottomFlag(true);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      setSpeakerEnabled(false);
      stopPlayingAudio(audioRef);
    }
  }, [isHelpOpen, inputText, isMenuOpen, sourceDialogFlag]);

  const handleScroll = () => {
    if (oldestOffset > 0 && chatBodyRef.current.scrollTop === 0) {
      populateChat(oldestOffset);
    }
  };

  useEffect(() => {
    const chatBodyCurrent = chatBodyRef.current;
    if (chatBodyCurrent) {
      chatBodyCurrent.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatBodyCurrent) {
        chatBodyCurrent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [oldestOffset]);

  async function populateChat(offset = null) {
    setMessageLoader(true);
    try {
      const res = await getMessages(offset);
      if (res?.status === 200) {
        let arr = [];
        res["data"]["Response"].forEach((message, idx) => {
          if (idx === res["data"]["Response"].length - 1) {
            setOldestOffset(message.offset - 1);
          }
          arr.unshift(message);
        });
        setChatArr((chatArr) => [...arr, ...chatArr]);
        if(arr[arr.length - 1]?.offset > currentOffset) {setCurentOffset(arr[arr.length - 1]?.offset)}
      }
      if(res?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");
      }
      if(res?.status === 500) {
        setToastErrorMessage(res?.data?.detail);
        setToastMessageSeverity(ToastSeverityMap.error);
        setShowToastMessage(true);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setMessageLoader(false);
    }
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleHelp = () => {
    setIsHelpOpen(!isHelpOpen);
  };

  const openSourceDialog = (sourceData) => {
    setSourceDialogFlag(true);
    setSelectedSourceData(sourceData);
    trackEvent("Source", "Click", "Open Source Block");
  };

  const closeSourceDialog = () => {
    setSourceDialogFlag(false);
    setSelectedSourceData(null);
  };

  function appendUserChatBlock(arr, input, image = null) {
    let defaultFlag = arr[arr.length - 1]?.bot?.welcome || false;
    let block = getUserMessageBlock(input?.selectedLanguage || input, defaultFlag, image);
    arr.push(block);
    setCurrentBlock(block);
    const newArr = appendLoaderBlock(arr);
    setChatArr(newArr);
    setIsLoading(true);
    return newArr;
  }

  function appendLoaderBlock(arr) {
    arr[arr.length - 1]["bot"] = dummyBlock["bot"];
    arr[arr.length - 1]["dummy"] = dummyBlock["dummy"];
    setCurrentBlock(arr[arr.length - 1]);
    return arr;
  }

  function updateLastPromptSelectedChip(index) {
    let arr = [...chatArr];
    if (arr[arr.length - 1]?.bot) {
      arr[arr.length - 1].bot.selectedChipIndex = index;
    }
    return arr;
  }
  
  function updateSelectedDocument(input, chatObj) {
    if (project === 'GHS' && chatObj["bot"]["welcome"]) {
        setSelectedDocument(input);
        saveSelectedDocument(input);
    }
  }

  async function getModelAnswer(arr, offset) {
    const maxTries = 30; // Max polling attempts
    let tries = 0;
    let newOffset = offset;
    let res = null;
  
    // Initial delay of 6 seconds before starting long polling
    await new Promise((resolve) => setTimeout(resolve, 6000));
  
    // Long polling logic with incremental delay
    while (tries < maxTries) {
      // Fetch messages with the current offset
      res = await getMessages(newOffset, "forward");
      if (res?.status === 200) {
        const responseOffset = res["data"].Response[0]?.offset;
        const modelResponseFlag = res["data"].Response[0]?.bot?.model_response_time;
        if (responseOffset && responseOffset > newOffset && modelResponseFlag) {
          // If a new offset is received, update the array and break the loop
          arr[arr.length - 1] = res["data"].Response[0];
          delete arr[arr.length - 1]["dummy"];
          res["data"].Response.forEach((chatObj, index) => {
            if (index > 0) {
              arr.push(chatObj);
            }
          });
          setChatArr(arr);
          setCurrentBlock(arr[arr.length - 1]);
          setCurentOffset(arr[arr.length - 1]["offset"]);
          break; // Exit polling when new offset is received
        }
        if (res?.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
        if (res?.status === 500) {
          // Handle error
          arr[arr.length - 1]["bot"]["error"] = { code: 500 };
          arr[arr.length - 1]["bot"]["datetime"] = new Date();
          delete arr[arr.length - 1]["dummy"];
          setToastErrorMessage(res?.data?.detail);
          setToastMessageSeverity(ToastSeverityMap.error);
          setShowToastMessage(true);
          break; // Exit the polling loop on error
        }
      }
  
      // Increment tries and wait with an increasing delay before retrying
      tries++;
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Incremental delay
    }
  
    // Check if polling exhausted without receiving a new offset
    if (tries === maxTries) {
      // Set an error indicating no new offset was received
      arr[arr.length - 1]["bot"]["error"] = { code: 501, message: "Model is not responding." };
      arr[arr.length - 1]["bot"]["text"] = modelNotRespondingConstant;
      arr[arr.length - 1]["bot"]["datetime"] = new Date();
      delete arr[arr.length - 1]["dummy"];
      const res = await modelInresponsive(getUserToken(), getUserRole());
      console.log(res);
    }
  
    // Set loading state to false after polling finishes
    setIsLoading(false);
  }
  

  async function submitResponse(index, input, customQueryFlag, image = null) {
    let arr = updateLastPromptSelectedChip(index);
    // For GHS usecase, update selected document
    updateSelectedDocument(input, arr[arr.length - 1]);
    arr = appendUserChatBlock(arr, input, image);
    const res = await submit(getUserToken(), getProcessedCustomPayload(project, input, audioUrls, selectedLanguage, customQueryFlag, image))
    setAudioUrls([]);
    if(res?.status === 200) {
        getModelAnswer(arr, currentOffset);
    } 
    if(res?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
    if(res?.status === 500) {
      setToastErrorMessage(res?.data?.detail);
      setToastMessageSeverity(ToastSeverityMap.error);
      setShowToastMessage(true);
    }
  }

  async function sendFeedback(feedback) {
    let temp = [...chatArr];
    const res = await sendChatFeedback(getUserToken(), temp[temp.length - 1]._id, feedback.value);
    if (res?.status === 200) {
      temp[temp.length - 1].bot.feedback_type = feedback.value;
      // console.log(temp[temp.length - 1]);
      setChatArr(temp);
    }
    if(res?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
    if(res?.status === 500) {
      setToastErrorMessage(res?.data?.detail);
      setToastMessageSeverity(ToastSeverityMap.error);
      setShowToastMessage(true);
    }
  }

  async function markFavourite(idx, id, starFlag) {
    let arr = [...chatArr];
    const res = await starBotAnswer(getUserToken(), id, starFlag);
    if (res?.status === 200) {
      arr[idx]["bot"]["starred"] = starFlag;
      setChatArr(arr);
    }
    if(res?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
    if(res?.status === 500) {
      setToastErrorMessage(res?.data?.detail);
      setToastMessageSeverity(ToastSeverityMap.error);
      setShowToastMessage(true);
    }
  }

  async function submitFeedback(feedback) {
    trackEvent("Feedback/Support", "Click", "send their feedback/concern");
    const res = await sendUserFeedback(getUserToken(), feedback);
    if (res?.status === 200) {
      setIsHelpOpen(false);
      setToastErrorMessage(res?.data?.detail);
      setToastMessageSeverity(ToastSeverityMap.success);
      setShowToastMessage(true);
    }
    if(res?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
    if(res?.status === 500) {
      setIsHelpOpen(false);
      setToastErrorMessage(res?.data?.detail);
      setToastMessageSeverity(ToastSeverityMap.error);
      setShowToastMessage(true);
    }
  }

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { dummyBlock } = constants;

  return (
    <>
      <div>
        <div
          className="flex flex-col h-screen bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url("/Assets/Images/bgWallpaper.svg")`,
          }}
        >
          <TopBar
            project={project}
            userData={userProfileData}
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            textSize={textSize}
            decreaseTextSize={decreaseTextSize}
            increaseTextSize={increaseTextSize}
            toggleHelp={toggleHelp}
          />
          <div
            className="overflow-hidden overflow-y-scroll scroll-smooth min-h-0 flex-grow"
            ref={chatBodyRef}
          >
            {messageLoader && (
              <div className="absolute w-[100vw] z-[2] flex justify-center my-4">
                <Loader />
              </div>
            )}
            <div className="flex items-center justify-center pt-2"></div>
            <div className="py-2 md:px-6 px-2">
              {chatArr.length > 0 && (
                <ChatQueue
                  project={project}
                  selectedLanguage={selectedLanguage}
                  chatArr={chatArr}
                  isLoading={isLoading}
                  openSourceDialog={openSourceDialog}
                  populateChat={populateChat}
                  currentBlock={currentBlock}
                  textSize={textSize}
                  sendFeedback={sendFeedback}
                  submitResponse={submitResponse}
                  audioRef={audioRef}
                  speakerEnabled={speakerEnabled}
                  setSpeakerEnabled={setSpeakerEnabled}
                  markFavourite={markFavourite}
                  setToastErrorMessage={setToastErrorMessage}
                  setShowToastMessage={setShowToastMessage}
                  scrollToBottonFlag={scrollToBottonFlag}
                />
              )}
            </div>
          </div>
          <BottomBar
            audioRef={audioRef}
            project={project}
            input={inputText}
            setInput={setInputText}
            setAudioUrls={setAudioUrls}
            audioUrls={audioUrls}
            selectedLanguage={selectedLanguage}
            send={submitResponse}
            textSize={textSize}
            isLoading={isLoading}
            stopPlayingAudio={stopPlayingAudio}
            setSpeakerEnabled={setSpeakerEnabled}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            setToastErrorMessage={setToastErrorMessage}
            setShowToastMessage={setShowToastMessage}
            setToastMessageSeverity={setToastMessageSeverity}
          />
        </div>
        {sourceDialogFlag && (
          <div className="absolute top-0 left-0 h-screen w-screen z-20">
            <SourceDialog
              selectedLanguage={selectedLanguage}
              sourceData={selectedSourceData}
              closeDialog={closeSourceDialog}
            />
          </div>
        )}
      </div>
      {isHelpOpen && (
        <div className="absolute top-0 left-0 h-screen w-screen z-20">
          <HelpFeedbackDialog textSize={textSize} selectedLangauge={selectedLanguage} toggleHelp={toggleHelp} submitFeedback={submitFeedback} />
        </div>
      )}
      {
        showToastMessage && <ToastMessage message={toastErrorMessage} severity={toastMessageSeverity} direction={"bottom"} close={setShowToastMessage}/>
      }
    </>
  );
};

export default ChatBody;
