import React from "react";
import { trackEvent } from "../../../Actions/Utils";

const MenuOptions = ({ id, value, icon, textSize, clickFun = () => {} }) => {
  const optionSize = {
    fontSize: `${textSize + 0.125}rem`,
  };
  const handleClick = () => {
    if (id === "help and feedback") {
      trackEvent("Feedback/Support", "Click", "Open Feedback/Support Icon (Hamburger)");
    }else if(id === "view tutorial"){
      trackEvent("Tutorial", "Click", "Open View tutorial");
    }
    clickFun(id);
  };
  return (
    <button className={`flex gap-3 py-5 px-8 font-normal`} style={optionSize} onClick={handleClick}>
      <img src={icon} alt="icon" className="min-w-[1.5rem]" />
      {value}
    </button>
  );
};

export default MenuOptions;
