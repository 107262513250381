export const DEMOashaDefaultChipsList = [
    {
        "id": "1",
        "value": {
            "en": "Pregnancy",
            "hi": "गर्भावस्था",
        },
        "img": "./Assets/Images/pregnancy.svg",
    },
    {
        "id": "2",
        "value": {
            "en": "Tuberculosis",
            "hi": "टीबी",

        },
        "img": "./Assets/Images/tuberculosis.svg",
    },
    {
        "id": "3",
        "value": {
            "en": "Child Health",
            "hi": "बाल स्वास्थ्य",
        },
        "img": "./Assets/Images/NewbornNutrition.svg",
    },
    {
        "id": "4",
        "value": {
            "en": "Immunisation",
            "hi": "टीकाकरण",
        },
        "img": "./Assets/Images/vaccination.svg",
    }
]

export const NHSRCashaDefaultChipsList = [
    {
        "id": "1",
        "value": {
            "en": "Pregnancy",
            "hi": "गर्भावस्था",
        },
        "img": "./Assets/Images/pregnancy.svg",
    },
    {
        "id": "2",
        "value": {
            "en": "Tuberculosis",
            "hi": "टीबी",

        },
        "img": "./Assets/Images/tuberculosis.svg",
    },
    {
        "id": "3",
        "value": {
            "en": "Child Health",
            "hi": "बाल स्वास्थ्य",
        },
        "img": "./Assets/Images/NewbornNutrition.svg",
    },
    {
        "id": "4",
        "value": {
            "en": "Immunisation",
            "hi": "टीकाकरण",
        },
        "img": "./Assets/Images/vaccination.svg",
    }
]

export const NHSRCanmDefaultChipsList = [
    {
        "id": "1",
        "value": {
            "en": "Pregnancy",
            "hi": "गर्भावस्था",
        },
        "img": "./Assets/Images/pregnancy.svg",
    },
    {
        "id": "2",
        "value": {
            "en": "Tuberculosis",
            "hi": "टीबी",

        },
        "img": "./Assets/Images/tuberculosis.svg",
    },
    {
        "id": "3",
        "value": {
            "en": "Child Health",
            "hi": "बाल स्वास्थ्य",
        },
        "img": "./Assets/Images/NewbornNutrition.svg",
    },
    {
        "id": "4",
        "value": {
            "en": "Immunisation",
            "hi": "टीकाकरण",
        },
        "img": "./Assets/Images/vaccination.svg",
    }
]

export const WCDawwDefaultChipsList = [
    {
        "id": "1",
        "value": {
            "en": "Anemia",
            "hi": "एनीमिया",
        },
        "img": "./Assets/Images/anemia.svg"
    },
    {
        "id": "2",
        "value": {
            "en": "Infant Feeding",
            "hi": "शिशु आहार",

        },
        "img": "./Assets/Images/infantFeeding.svg"
    },
    {
        "id": "3",
        "value": {
            "en": "Newborn",
            "hi": "नवजात शिशु",
        },
        "img": "./Assets/Images/newborn.svg"
    },
    {
        "id": "4",
        "value": {
            "en": "Malnutrition",
            "hi": "कुपोषण",
        },
        "img": "./Assets/Images/malnutrition.svg"
    },
]

export const CTDashaDefaultChipsList = [
    {
        "id": "1",
        "value": {"en": "100 Days Campaign", "hi": "100 दिन अभियान"},
        "img": null,
    },
    {
        "id": "2",
        "value": {"en": "Support to TB Patients", "hi": "टीबी मरीजों को समर्थन"},
        "img": null,
    },
    {
        "id": "3",
        "value": {"en": "Schemes & Incentives", "hi": "योजनाएं और इंसेंटिव"},
        "img": null,
    },
    {
        "id": "4",
        "value": {"en": "About TB", "hi": "टीबी के बारे में"},
        "img": null,
    }
]

export const CTDchoDefaultChipsList = [
    {
        "id": "1",
        "value": {"en": "100 Days Campaign", "hi": "100 दिन अभियान"},
        "img": null,
    },
    {
        "id": "2",
        "value": {"en": "Support to TB Patients", "hi": "टीबी मरीजों को समर्थन"},
        "img": null,
    },
    {
        "id": "3",
        "value": {"en": "Patient Flow", "hi": "मरीजों की प्रक्रिया"},
        "img": null,
    },
    {
        "id": "4",
        "value": {"en": "Nikshay", "hi": "निक्षय"},
        "img": null,
    }
]