import { trackEvent } from "../../../Actions/Utils";
const OptionChips = ({
  option,
  selectedLanguage,
  send = () => {},
  textSize,
  disabled,
  index,
  selectedChipIndex
}) => {
  function saveResponse(input, image) {
    send(index, input, false, image);
  }
  return (
    <div
      className={`flex items-center justify-start rounded-xl border border-solid border-1 border-[var(--primary-dark)] gap-3  min-h-[3rem] px-2 ${disabled ? 'bg-[var(--light-neutral-3)] opacity-60 border-[var(--light-neutral-3)]': 'bg-[var(--neutral-white)]'} ${selectedChipIndex === index ? 'opacity-100 bg-[var(--primary-dark)] text-[var(--neutral-white)]': 'text-[var(--primary-dark)]'}`}
      onClick={() => {
        saveResponse(option.value[selectedLanguage], option.img);
        trackEvent("Default Chips", "Click", "Default chips from chat");
      }}
      style={{ fontSize: `${textSize}rem` }}
    >
      {option.img && <img
          src={option.img}
          alt={option.value[selectedLanguage]}
          className="h-100 w-100 mt-2"
        />}
      <div className="text-center">
        {option.value[selectedLanguage]}
      </div>
    </div>
  );
};

export default OptionChips;
