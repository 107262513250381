import { useState } from "react";
import { submitLabel, subTitle, textPlaceholder, title } from "../../../Constants/TB/HelpFeedbackConstants";
import NextBtn from "../NextBtn/NextBtn";
import { trackEvent } from "../../../Actions/Utils";

const HelpFeedbackDialog  = ({textSize, selectedLangauge, toggleHelp = () => {}, submitFeedback = () => {}}) => {
    const [feedback, setFeedback] = useState("");
    const handleCloseFeedback = () => {
        toggleHelp()
        trackEvent("Feedback/Support", "Click", "Close Feedback/Support Screen");
    };
    
    return (
    <div>
        <div className="fixed inset-0 bg-gray-800 opacity-50"></div>
        <div className="fixed inset-0 flex items-center justify-center p-2">
            <div
            className="relative bg-[var(--primary-medium-2)] p-6 rounded-lg shadow-xl w-[85%]"
            style={{ fontSize: `${textSize}rem` }}
            >
            <div className="relative overflow-y-scroll h-[100%]">
                <div className="flex flex-col items-center justify-center mb-4">
                    <div className="text-[24px] leading-9 font-[600] mb-2">
                        {title[selectedLangauge]}
                    </div>
                    <div className="text-[16px]  font-[400] leading-6 text-center mb-2">
                        {subTitle[selectedLangauge]}
                    </div>
                    <textarea
                        rows="5"
                        placeholder={textPlaceholder[selectedLangauge]}
                        className="mt-4 chat-input p-2 rounded-lg w-[100%] outline-none resize-none placeholder:italic placeholder:text-base" 
                        onChange={(event) => {
                            setFeedback(event.target.value)
                        }}
                    />
                    <div className={`mt-4 ${feedback.length === 0 ? 'pointer-events-none': ''}`}>
                        <NextBtn leftIcon={true} bgColor={"var(--primary-dark)"} color={"var(--neutral-white)"} centerSpacing={true} value={submitLabel[selectedLangauge]} icon={"/Assets/Images/sendFeedbackIcon.svg"} nextClick={() => submitFeedback(feedback)} />
                    </div>
                </div>
            </div>
            <div
                className="absolute top-[-1.25rem] right-[-1.25rem] rounded-full bg-[var(--almost-black)] flex items-center justify-center p-1"
                onClick={handleCloseFeedback}
            >
                <img src={"./Assets/Images/crossIcon.svg"} alt="Close"/>
            </div>
            </div>
        </div>
    </div>
    )
}

export default HelpFeedbackDialog;